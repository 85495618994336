<template>
  <div style="padding: 20px;">
    <button @click="toggleImageVisibility()" class="button">Toggle Images</button>
  </div>
</template>
  
<script>
import { toggleImagesVisible } from '@/store';
  export default {
    name: 'ImageToggle',
    methods: {
      toggleImageVisibility() {
        toggleImagesVisible();
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button {
  font-size: 2em;
}

@media only screen and (max-width: 500px) {
  .button {
    font-size: 1em;
  }
}
</style>
