<template>
  <div v-for="card in cards" :key="card.CardName" class="cards">
    <a :href="card.ScryfallURL" target="_blank"><span class="cardName">{{ card.CardName }}</span></a> ({{ card.Price }} / {{ card.Foil }}) for <b>{{ card.Deck }}</b>
  </div>
</template>

<script>
import proxies from '../assets/proxies.json'

  export default {
    name: 'CardList',
    data() {
      return {
        cards: proxies,
      }
    },
    mounted() {
      console.log('started')
      console.log(this.cards)
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cardName {
  font-weight: bold
}

.cards {
  font-size:2em;
}

@media only screen and (max-width: 500px) {
  .cards {
    font-size:.85em;
  }
}
</style>
